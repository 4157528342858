import { useState, useEffect } from "react";
import { scrollTo } from "@utils/scrollTo";

export default function ScrollToTop() {
  const [showElement, setShowElement] = useState(false);

  const handleScroll = () => {
    const position = typeof window !== "undefined" ? window.scrollY : 0;
    if (position >= 300) {
      setShowElement(true);
    }
    if (position < 300) {
      setShowElement(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <>
      {showElement && (
        <div
          id="scrollToTop"
          onClick={() => {
            scrollTo();
          }}
          className="bluelink text-2xl scrollToTop"
        >
          <span className="fa-stack fa-lg">
            <i className="scrollToTop_background fa fa-stack-2x"></i>
            <i className="scrollToTop_symbol fa fa-reply fa-rotate-90 fa-stack-1x"></i>
          </span>
        </div>
      )}
    </>
  );
}
