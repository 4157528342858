'use client'

//////////////////////////////////////////
// scrollTo
//////////////////////////////////////////
export function scrollTo(id, offset, delay) {
  if (typeof window !== 'undefined') {
    let top = 0 // default = window top

    // let scrollHeight = Math.max(
    //   document.body.scrollHeight, document.documentElement.scrollHeight,
    //   document.body.offsetHeight, document.documentElement.offsetHeight,
    //   document.body.clientHeight, document.documentElement.clientHeight
    // );
    // console.log('Full document height, with scrolled out part: ' + scrollHeight);

    let y = 0 // if you need more offset
    if (offset) {
      y = parseInt(offset)
    }

    let d = 200 // small default delay
    if (delay) {
      d = parseInt(delay)
    }

    if (id && typeof document !== 'undefined') {
      const violation = document.getElementById(id)
      if (violation) {
        top = violation.offsetTop + y
      }
    }

    if (d > 0) {
      setTimeout(function () {
        window.scrollTo({
          top: top,
          behavior: 'smooth' || 'auto',
        })
      }, d)
    } else {
      window.scrollTo({
        top: top,
        behavior: 'smooth' || 'auto',
      })
    }
  }
}
